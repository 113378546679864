import { ResumeTableBox } from "../../components/Resume"
import { Groups } from "../../components/Groups"
import { Title } from "../../components/Title"
import { Footer_Button } from "../../components/Footer_Button"
import { ButtonResume } from "../../components/ButtonResume"
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useFetch } from "../../hooks//useFetch"
import { useEffect, useState } from "react"
import { Instruction } from "../../components/Title"




const Resume = () =>{
    let ideventObj = {id:""}
    //const urlParticipants = "http://localhost:3000/participants"
    const urlParticipants = "https://divconta.com.br:21120/participants"
    const navigate = useNavigate();
    const [buttonSelect, setButtonSelect] = useState()
    const [expensesEvents, setExpensesEvents] = useState([])
    const [resumeSelect, setResumeSelect] = useState()
    const [participantsEvents, setParticipantsEvents] = useState()
    const [detailsSelect,setDetailsSelect] = useState()
    const [expandSelect, setExpandSelect] = useState()
    const [sendSelect, setSendSelect] = useState()
    const [participantsOrder, setParticipantsOrder] = useState([])
    const [expensesOrder, setExpensesOrder] = useState([])
    const [groupEvents, setGroupEvents] = useState([])
    const [callAddEvent, setCallAddEvent] = useState(false)
    const [confirmExpand, setConfirmExpand] = useState(true)
    const { data: dataReceive, error: receiveBD, httpConfig } = useFetch (urlParticipants);
    const {idevent,where} = useParams()
   
   
    useEffect(()=>{
        setButtonSelect(where)
    },[where])
    
    ideventObj.id = idevent
    const expensesCreat = {
        idevent: idevent,
        expenses: "Café"
    }
    

    const functionButton = [
        () =>{
            navigate('/Participants/' + idevent);
        },
        () =>{
            Add()
        },
        () =>{
            Delete()
        },
        () =>{
            navigate('/Results/' + idevent);
        }
    ]

    let buttons = []
    if(buttonSelect == "Group"){
        buttons =["Evento","Desagrupar","Resultado"]
        functionButton.splice(1, 2);
        functionButton.splice(1, 0, () => {ungroupParticipant()})

    }
    else{
        buttons =["Evento","Add","Delete","Resultado"]
    }
    

    useEffect(()=>{
        if(buttonSelect == "Group"){
            httpConfig(ideventObj,'POST','/getGroup')
        }
        else{
            httpConfig(ideventObj,'POST','/getsend')
        }
        
    },[buttonSelect,])


    //console.log("RECEIVE_DB",receiveBD)
   
   
    useEffect(()=>{
        if(buttonSelect == "Group"){
            if(receiveBD != null){
                setGroupEvents(receiveBD)
            }

        }
        else{
            if(receiveBD != null){
                //const participants_ = JSON.parse(participantsBD)
                const orderParticipants = receiveBD.participantsOrder.map((item, i) => {
                    return item;
                });
                setParticipantsOrder(orderParticipants) 
                const orderExpenses = receiveBD.expensesOrder.map((item, i) => {
                    return item;
                });
                setExpensesOrder(orderExpenses) 
                const participantsEvent = receiveBD.participantsEvent.map((item, i) => {
                    return{
                        id:item.id,
                        name:item.name,
                        contribuitionAll:item.contribuitionAll,
                        amountOwedAll:item.amountOwedAll,
                        amountToPay:item.amountToPay,
                        amountToReceive:item.amountToReceive,
                    } 
                });
                setParticipantsEvents(participantsEvent) 
                const expenses_ = receiveBD.event.map((item, i) => {
                    return{
                        name: item.name,
                        amountExpenses: item.amountExpenses
                    } 
                });
                
                setExpensesEvents(expenses_) 
    
            }
        }
        
        
    },[receiveBD, callAddEvent])

    
    

    const ResumeSelect_ = (resumeSelect_) =>{
        setResumeSelect(resumeSelect_)
    }

    const DetailsSelect_ = (detailsSelect_) =>{
        setDetailsSelect(detailsSelect_)
    }

    const ExpandSelect_ = (expandSelect_) =>{
        setExpandSelect(expandSelect_)
        setConfirmExpand(true)
    }

    const ButtonSelect = (id) =>{
        //setButtonSelect(!buttonSelect)
        setButtonSelect(id)
        setConfirmExpand(true)
    }

   // buttonSelect ? buttons[1] = "Participantes" : buttons[1] = "Despesas"
   
    useEffect(()=>{
        console.log("PARTICIPANTS_EVEMTS",participantsEvents)
        console.log("DETAILS_SELECT",detailsSelect)
        console.log("RESUME_SELECT",resumeSelect)
        if(buttonSelect == "Expenses"){
                setSendSelect({
                    idevent: idevent,
                    idparticipant: detailsSelect && detailsSelect[resumeSelect],
                    indexExpense: resumeSelect,
                })
            
        }
        if(buttonSelect == "Participants"){
            
            if(participantsEvents && resumeSelect >= 0){
                setSendSelect({
                    idevent: idevent,
                    idparticipant: participantsEvents[resumeSelect].id,
                    indexExpense: detailsSelect && detailsSelect[resumeSelect],
                })
                console.log("SEND_SELECT",sendSelect)
            }
        }
        if(buttonSelect == 'Group'){
            console.log("DETAILS_SELECT",detailsSelect)
            console.log("RESUME_SELECT",resumeSelect)
            console.log("GROUP_EVENT",groupEvents)
            setSendSelect({
                idevent: idevent,
                idparticipant: resumeSelect != undefined && (detailsSelect ? detailsSelect[resumeSelect] : groupEvents.groupEvent[resumeSelect].group),
                group: resumeSelect != undefined && groupEvents.groupEvent[resumeSelect].group,
                indexExpense: resumeSelect != undefined && resumeSelect,
            })
        }
        
    },[detailsSelect,resumeSelect])
  
    const editParticipant = (idParticipant,key,indexExpense,value,back) =>{
        const editSendObj = {
            idevent: idevent,
            idParticipant: idParticipant,
            key: key,
            indexExpense: indexExpense,
            value, value,
            back: back
        }
        console.log("EDIT_SEND",editSendObj)
        httpConfig(editSendObj,'POST','/updateParticipant')
        

    }
    const Add = (table=false) =>{
        const verifyExpand = expandSelect.every(element => element === false)
        //console.log("EXPAND_SELECT",expandSelect[resumeSelect])
        console.log("TABLE",table)
        if(expandSelect[resumeSelect]){
            if(buttonSelect == "Expenses"){
                navigate('/Insert/'+idevent+'/'+'expense'+'/'+resumeSelect + '/' + receiveBD.eventName)
            }
            if(buttonSelect == "Participants"){
                navigate('/Insert/'+idevent+'/'+'participant'+'/'+sendSelect.idparticipant + '/' + receiveBD.eventName)
            }
            
        }
        if(!expandSelect[resumeSelect] && !table){
            if(buttonSelect == "Expenses"){
                navigate('/Insert/'+idevent+'/'+'expenseInput' + '/' + resumeSelect + '/' + receiveBD.eventName)
            }
            if(buttonSelect == "Participants"){
                navigate('/Insert/'+idevent+'/'+'participantInput' + '/' + sendSelect + '/' + receiveBD.eventName)
            }
        }
    }

    const agroupParticipnt = (group,newGroup) =>{
        console.log("ADD_PARTICIPANT_DRAG",group.leader)
        console.log("ADD_PARTICIPANT_DROP",newGroup.leader)
        const agroup = {
            idevent: idevent,
            group: group,
            newGroup: newGroup
        }

        httpConfig(agroup,'POST','/agroup')
    }

    const ungroupParticipant = () =>{
        console.log("SEND_SELECT",sendSelect)
        httpConfig(sendSelect,'POST','/ungroup')
        setDetailsSelect()

    }
    
    const  Delete = (table = false) =>{
        if(expandSelect[resumeSelect] && detailsSelect){
            
            httpConfig(sendSelect,'POST','/cleanExpenseParticipant')
            console.log("PARTICIPANT_SELECT",detailsSelect)
        }
        if(!expandSelect[resumeSelect] && !table){    
            //buttonSelect ? httpConfig(sendSelect,'POST','/deleteExpense') : httpConfig(sendSelect,'POST','/deleteParticipant')
            //setResumeSelect(resumeSelect +1)
            if(buttonSelect == "Expenses"){
                httpConfig(sendSelect,'POST','/deleteExpense')
            }
            if(buttonSelect == "Participants"){
                console.log("SEND_SELECT_01",sendSelect)
                console.log("RESUME_SELECT",resumeSelect)
                console.log("PARTICIPANT_EVENT",participantsEvents)
                httpConfig(sendSelect,'POST','/deleteParticipant')
                console.log("PARTICIPANT_EVENT",participantsEvents)
               

                if(participantsEvents.length>1){
                    setSendSelect({
                        idevent: idevent,
                        idparticipant:resumeSelect < (participantsEvents.length-1) ? participantsEvents[resumeSelect+1].id :participantsEvents[resumeSelect].id,
                        indexExpense: detailsSelect && detailsSelect[resumeSelect],
                    })
                }

            }
            setConfirmExpand(false)
        }
        setDetailsSelect()
        
        
        
    }

    /*console.log("EXPAND_SELECT",expandSelect)*/
    /*console.log("RESUME_SELECT",resumeSelect)
    console.log("DETAILS_SELECT",detailsSelect)*/
    //console.log("GORUP_EVENTS",groupEvents)
    //console.log("SEND_SELECT",sendSelect)
    //console.log("EXPENSES_ORDER",expensesOrder)
    //console.log("BUTTON_SELECT",buttonSelect)
    return(
        <div className="Container">
            <Title title ={receiveBD && receiveBD.eventName}/>
            <ButtonResume  ButtonSelect = {ButtonSelect} ResumeSelect_ = {ResumeSelect_} buttonSelect={buttonSelect}/>
            {buttonSelect != "Group"?
                <ResumeTableBox expensesEvents={expensesEvents} participantsOrder={participantsOrder} expensesOrder = {expensesOrder} ResumeSelect_ = {ResumeSelect_} ExpandSelect_ = {ExpandSelect_} DetailsSelect_ = {DetailsSelect_} buttonSelect={buttonSelect} participantsEvents ={participantsEvents} editParticipant = {editParticipant} ButtonSelect = {ButtonSelect} resumeSelect={resumeSelect} confirmExpand={confirmExpand} Add={Add} Delete={Delete} />
                :
                <div style={{height:"79%"}}>
                    <Instruction instruction={"*Selecione, arraste e solte para agrupar participantes"} style = {{margin:"4%", paddingButtom:"2%",}}/>
                    <Groups groupEvents={groupEvents} DetailsSelect_ = {DetailsSelect_} expensesEvents={expensesEvents} buttonSelect={buttonSelect} participantsEvents ={participantsEvents} agroupParticipnt={agroupParticipnt} resumeSelect={resumeSelect} ResumeSelect_ = {ResumeSelect_}/>
                </div>
            }
            
            <Footer_Button buttons={buttons} functionButton={functionButton} />
        </div>
        
    )       
}

export default Resume
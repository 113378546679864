import { TextHome } from "../../components/Text"
import { Title } from "../../components/Title"
import { Footer_Button } from "../../components/Footer_Button"
import { useNavigate } from 'react-router-dom';




import "../../css/Container.css"



const Home = () =>{

   
    const navigate = useNavigate();

    const buttons =["Iniciar"]
    const functionButton = [
        () =>{//Function Button Voltar
            navigate('/Events');
        },
    ]

    
    return(
        <div className="Container">
            <Title title ={"Divide a Conta"}/>
            <TextHome/>
            <Footer_Button buttons={buttons} functionButton={functionButton} />
        </div>
        
    )        
}

export default Home
import {Tables} from "../Tables"
import { useEffect, useState } from "react"
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {ButtonExpand} from "../Button"
import "../../css/Input_events.css"
import { useDrag } from 'react-dnd'
import { useDrop } from "react-dnd"
import { useMemo } from "react"
import { useFetch } from "../../hooks//useFetch"

const GroupsDrag = ({group, body, DetailsSelect_, participantsEvents, buttonSelect, expensesEvents, expand, Expand, index, agroupParticipnt, resumeSelect, ResumeSelect_}) =>{



    const [{isDragging}, drag] = useDrag(() => ({
        type:'box',
        item: {id: group},
        
        collect: (monitor) => ({
            isDragging: monitor.isDragging,
           
        }),
        
    }),[group])
    
    
        const [{isOver}, drop] = useDrop(() => ({
            accept: 'box',
            drop: (item) => agroupParticipnt(item.id.group, group.group),
            collect: (monitor) => ({
                isOver: !!monitor.isOver
            }),
        }),[group])

           


    return(
        
        <div  ref={resumeSelect == index ? drag : drop} 
            className="div_input"
            onMouseDown={(e)=>{
                ResumeSelect_(index)   
            }}
            style={{backgroundColor:  resumeSelect == index && "#bec8d4", }}
        >
            <div className="textInputParticipants">
                
                <p>{group.leader} ({group.numberParticipants})</p>
                
                <ButtonExpand Expand={Expand} index={index} expand ={expand} />
            </div>
            
            <div>
                <div className="textResumeParticipant">
                    <p>Gastos:</p>
                    <p style={{color:'#993030'}}>{group.contribuitionAll}</p>
                </div>
                <div className="textResumeParticipant">
                    <p>Devido:</p>
                    <p style={{color:'#237A27'}}>{group.amountOwedAll}</p>
                </div>
                <div className="textResumeParticipant">
                    {group.amountToPay > 0 ?
                        <div>
                            <p>Pagar:</p>
                            <p style={{color:'#993030'}}>{group.amountToPay}</p>
                        </div>
                        :
                        <div>
                            <p>Receber:</p>
                            <p style={{color:'#223497'}}>{group.amountToReceive}</p>
                        </div>
                    }
                </div>
            </div>   
            
            {expand[index] && 
                <div className="divInputParticipants">
                    <Tables bodys = {body} DetailsSelect_ = {DetailsSelect_} heads ={["Nome", "Gasto", "Devido", "Quitar"]} expensesEvents = {expensesEvents} participantsEvents={participantsEvents} buttonSelect = {buttonSelect} resumeSelect={resumeSelect} ResumeSelect_={ResumeSelect_}/>
                </div>
            }
        </div>
               
           

       
    )
    
}

export default GroupsDrag; 
import { Title } from "../../components/Title"
import { SubTitle } from "../../components/Title"
import { Footer_Button } from "../../components/Footer_Button"
import { Inputs } from "../../components/Input"
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useFetch } from "../../hooks//useFetch"
import { useEffect, useState } from "react"
import { ButtonResume } from "../../components/ButtonResume"


const Insert = () =>{

    /*const urlParticipants = "http://localhost:3000/participants"
    const urlEvent = "http://localhost:3000/events"*/

    const urlParticipants = "https://divconta.com.br:21120/participants"
    const urlEvent = "https://divconta.com.br:21120/events"

    let ideventObj = {id:"",index:""}
    let buttons =["Cancelar", "Inserir"]
    
    const navigate = useNavigate();
    const {idevent,sender,index, eventName} = useParams()
    ideventObj.id = idevent
    ideventObj.index = index
    const [input,setInput] = useState(true)
    const [list,setList] = useState([])
    const [send,SetSend] = useState()
    const [callBack,SetCallBack] = useState(false)

    const { data: event, error: listBD, httpConfig: httpConfigEvents } = useFetch (urlEvent);
    const [titleText,setTitleText] = useState()
    //console.log("ID_OBJ",ideventObj)
    useEffect (()=>{
        if(sender == 'expenseInput'){
            httpConfigEvents(ideventObj,'POST','/getParticipantsEvent')
            setTitleText('Inserir Nova Despesa')
            
        }
        if(sender == 'participantInput'){
            httpConfigEvents(ideventObj,'POST','/getExpensesEvent')
            setTitleText('Inserir Novo Participante')
            
        }
        if(sender == 'expense'){
            httpConfigEvents(ideventObj,'POST','/getExpensesEventNot')
            setTitleText('Atibuir Participante')
            setInput(false)
        }
        if(sender == 'participant'){
            httpConfigEvents(ideventObj,'POST','/getExpensesPartNot')
            setTitleText('Atibuir Despesa')
            setInput(false)
        }
    },[])
    useEffect(()=>{
        setList(listBD)
    },[listBD])

    const AddSend = (listObj)=>{
        SetSend(listObj)
    }

    //console.log("LIST_BD",listBD)
    //console.log("LIST",list)
    console.log("SEND",send)
    

    const functionButton = [
        () =>{//Function Button Voltar
            if(sender == 'participant' || sender == 'participantInput'){
                navigate('/Resume/' + idevent + "/Participants");
            }
            if(sender == 'expense' || sender == 'expenseInput'){
                navigate('/Resume/' + idevent + "/Expenses");
            }
            
        },
       
        () =>{
            
            if(sender == 'participantInput'){
                httpConfigEvents(send,'POST',"/creatparticipants")
                SetCallBack(!callBack)
            }
            if(sender == 'expenseInput'){
                httpConfigEvents(send,'POST',"/createxpenses")
                SetCallBack(!callBack)
            }
            if(sender == 'expense'){
                httpConfigEvents(send,'POST','/updateParticipantsExpenses')
                SetCallBack(!callBack)
            }
            if(sender == 'participant'){
                httpConfigEvents(send,'POST','/updateParticipantExpenses')
                SetCallBack(!callBack)
            }

            console.log("LIST_BD",listBD)
            //navigate('/Resume/' + idevent);
        }
    ]

    useEffect(()=>{
        if(callBack){
            if(sender == "participant" || sender == "participantInput" ){
                if(list.response === "OK"){
                    navigate('/Resume/' + idevent + "/Participants")
                }
            }
            if(sender == "expense" || sender == "expenseInput" ){
                if(list.response === "OK"){
                    navigate('/Resume/' + idevent + "/Expenses")
                }
            }
            
        }
        
    },[callBack,list])

    return(
        <div className="Container">
            <Title title ={eventName}/>
            <SubTitle subTitle={titleText} hightText={"100%"} />
            <Inputs list = {list} input={input} sender ={sender} idevent={idevent} AddSend={AddSend} callback={callBack}/>
            <Footer_Button buttons={buttons} functionButton={functionButton} />
        </div>
    )        
}

export default Insert
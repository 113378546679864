import { InputParticipants } from "../../components/Input"
import { Title } from "../../components/Title"
import { Footer_Button } from "../../components/Footer_Button"
import { useNavigate, useParams } from 'react-router-dom';

import { useFetch } from "../../hooks//useFetch"




import "../../css/Container.css"
import { useState,useEffect } from "react";



const Participants = () =>{

    let ideventObj = {id:""}

    const url = "http://localhost:3000/participants"
    const urlEvent = "http://localhost:3000/events"
    const navigate = useNavigate();
    const {idevent} = useParams()
    
    const [expenses, setExpenses] = useState([])
    const [clean, setClean] = useState(false)
    const [participants, setParticipants] = useState({
        idevent:"",
        name:"",
        expenses: [],
        payto:[],
        receivefrom:[]
    })

    const data = ["Drink","Cerveja"]

    const { data: tabela, error: error, httpConfig } = useFetch (url);
    const { data: xc, error: event, httpConfig: httpConfigEvent } = useFetch (urlEvent);

    ideventObj.id = idevent;
    console.log("IDEVENTEOBJ",ideventObj)
    

    useEffect(()=>{
        httpConfigEvent(ideventObj,'POST','/getidevent')
    },[])
    useEffect(()=>{
        if(event != null){
            const expenses_ = JSON.parse(event.expenses)
            setExpenses(expenses_) 
        }
        
    },[event])
  
    const Add = (expenses,Participant_,idevent) =>{
        setParticipants({
            ...participants,
            idevent: idevent,
            name: Participant_,
            expenses: expenses
        })
    }
    const buttons =["Evento", "Add", "Resumo"]
    //console.log("PARTICIPANT",participants)
    const functionButton = [
        () =>{//Function Button Voltar
            navigate('/Events');
        },
        () =>{
            httpConfig(participants,"POST","/insert")
            setClean(!clean)
        },
        () =>{
            navigate('/Resume/'+ idevent );
        }
    ]

    return(
        <div className="Container">
            <Title title ={"Participantes"}/>
            <InputParticipants event={expenses} add = {Add} clean = {clean} idevent = {idevent}/>
            <Footer_Button buttons={buttons} functionButton={functionButton} />
        </div>
        
    )        
}

export default Participants
import { useEffect, useState } from "react"
import "../../css/Table.css"
import { Instruction } from "../Title"
import { Button_Delete, Button_More } from "../Button"




const Tables = ({bodys =[],heads, DetailsSelect_, expensesEvents, participantsEvents, buttonSelect, resumeSelect, editParticipant, ButtonSelect, Expand, Add, Delete}) =>{
   
    console.log("Bodys",bodys)
    const [detailsTableSelect, setDetailsTableSelect] = useState()
    const [editId, setEditId] = useState({key:null,index:null})
    const [detailsSelect,setDetailsSelect] = useState([])
    const [startTime, setStartTime] = useState(null)
    const [lastTouch, setLastTouch] = useState(null)
    const [editor, setEditor] = useState()
    const [ checkedPart, setCheckedPart] = useState(false)

    let keys
    const expensesArray = expensesEvents.map((item)=>item.name)
    const verify = bodys.length

    if (verify > 0) {
        keys = Object.keys(bodys[0])
        keys.splice(0,2)
        if(!checkedPart){
            keys.splice(1,1)
        }
    }

    useEffect(()=>{
        if(buttonSelect == "Expenses" ){
            if(expensesEvents){
                if(detailsSelect.length == 0){
                    const detailsSelect_ = expensesEvents.map((item, i) => {return false})
                    setDetailsSelect(detailsSelect_)
                }
            }
        }
        if(buttonSelect == "Participants" || buttonSelect == "Group"){
            if(participantsEvents){
                if(detailsSelect.length == 0){
                    const detailsSelect_ = participantsEvents.map((item, i) => {return false})
                    setDetailsSelect(detailsSelect_)
                }
            }
        }
        
    },[buttonSelect,participantsEvents,expensesEvents])
   

    useEffect(()=>{
        if(buttonSelect == "Expenses"){
            if(detailsTableSelect >=0){
                const detailsSelect_ = detailsSelect.map((item, i) => {
                    if (i === resumeSelect) {       
                      return bodys[detailsTableSelect].id
                    } else {
                      return item
                    }
                });
                setDetailsSelect(detailsSelect_)
                DetailsSelect_(detailsSelect_)           
            }
        }
        if(buttonSelect == "Participants"){
            if(detailsTableSelect >=0){
                const detailsSelect_ = detailsSelect.map((item, i) => {
                    if (i === resumeSelect) {       
                      return bodys[detailsTableSelect].idExpense
                    } else {
                      return item
                    }
                });
                setDetailsSelect(detailsSelect_)
                DetailsSelect_(detailsSelect_)           
            }

        }
        if(buttonSelect == "Group"){
            if(detailsTableSelect >=0){
                const detailsSelect_ = detailsSelect.map((item, i) => {
                    if (i === resumeSelect) {       
                      return bodys[detailsTableSelect].idParticipant
                    } else {
                      return item
                    }
                });
                setDetailsSelect(detailsSelect_)
                DetailsSelect_(detailsSelect_)           
            }
        }

    },[detailsTableSelect, ])
        

    

    const handleDoubleClick = (key,index,i,body)=>{
        if (i == 0){
            
            if(buttonSelect == 'Participants'){
                Expand(body.idExpense)
                ButtonSelect('Expenses')
            }
            if(buttonSelect == 'Expenses'){
                const indexParticipant = participantsEvents.findIndex(participant => participant.id === body.id)
               
                Expand(indexParticipant)
                ButtonSelect('Participants')
            }
            
        }
        setEditId({
            key: key,
            index: index
        });
        
    }

    const handleTouchStart = (e) => {
        setStartTime(new Date());
        const lastTouch_ = { x: e.touches[0].clientX, y: e.touches[0].clientY}
        setLastTouch(lastTouch_)

    }

    const handleTouchMove = (e) => {
        const deltaX = Math.abs(e.touches[0].clientX - lastTouch.x);
        const deltaY = Math.abs(e.touches[0].clientY - lastTouch.y);
        if(deltaX > 30 || deltaY > 30){
            setStartTime(null)
        }
    }
    
    const handleTouchEnd = (key,index,i,body) => {
        if (startTime !== null) {
          const currentTime = new Date();
          const diff = currentTime - startTime;
            if (diff >= 600) {
                handleDoubleClick(key,index,i,body)
            } 
            else{
                handleDoubleClick(null)
            }
          setStartTime(null);
        }
    }

    const handleParticipation = () =>{
        setCheckedPart(!checkedPart)
    }

    

    /*useEffect(() => {
        // Adicionar um event listener para capturar cliques no documento
        document.addEventListener('mousedown', handleDocumentClick)
    
        // Remover o event listener quando o componente for desmontado
        return () => {
          document.removeEventListener('mousedown', handleDocumentClick)
        };
      }, [editor]);*/

      const handleDocumentClick = () =>{
        
        handleDoubleClick(null)
        if(editor && editor.check){
            editParticipant(editor.idParticipant, editor.key,editor.indexExpense,editor.value) 
        }
        setEditor({
            ...editor,
            value:"",
            check: false
        })
        
      }
      

    console.log("DETAILS_TABLE",detailsTableSelect)
    return(
        <div style={{display:'flex',flexDirection:"column"}}>
            <table className="tableResume"
                onClick={(e)=>{handleDocumentClick()}}
                onTouchStart={(e)=>{handleDocumentClick()}}
            >
                <thead>
                    <tr style={{borderBlock:"2px"}}>
                        <th style={{width:'40%',textAlign:'left'}}>{heads[0]}</th>
                        {checkedPart && <th style={{width:'20%',textAlign:'center'}}>{heads[1]}</th>}
                        <th style={{width:'20%',textAlign:'center'}}>{heads[2]}</th>
                        <th style={{width:'20%',textAlign:'center'}}>{heads[3]}</th>
                    </tr>
                </thead>
                <tbody>
                    {verify > 0 && bodys.map((body,index)=>(
                        
                        <tr 
                            
                            onClick={(e)=>{
                                // SetDatailsSelect(index)
                                setDetailsTableSelect(index)
                                //handleDoubleClick(null)
                            }}
                            style={{backgroundColor: detailsTableSelect == index && "#a7a792", cursor: 'pointer'}}
                        >
                            {keys.map((key,i)=>(
                                
                                <td style={{textAlign: i == 0 ? "left" : 'center'}}
                                    onDoubleClick={()=>handleDoubleClick(key,index,i,body)}
                                    onTouchStart={(e)=>handleTouchStart(e)}
                                    onTouchMove={(e)=>handleTouchMove(e)}
                                    onTouchEnd={()=>handleTouchEnd(key,index,i,body)}
                                
                                >
                                    {buttonSelect != "Group" && editId.key === key && editId.index === index && (i < 3 ) && (i > 0)?(
                                        <div className="inputEditTable">
                                            
                                            <input
                                                type="number"
                                                //inputMode="number"
                                                defaultValue={body[key] == 0 ? "" : body[key]}
                                                value={editor.value == "" ? body[key] == 0 ? "" : body[key] : editor.value }
                                                autoFocus="false"
                                                onChange={(e)=>{
                                                    console.log("E",e.target.value)
                                                    console.log("Elenght",e.target.value.toString().length)
                                                    console.log("VALUE",editor.value)
                                                    console.log("VALUElenght",editor.value.toString().length)
                                                    if(buttonSelect == "Expenses"){
                                                       
                                                        setEditor({
                                                            idParticipant:body.id,
                                                            key: key,
                                                            indexExpense: resumeSelect,
                                                            value: parseFloat(e.target.value.toString().includes('.')  ? parseFloat(e.target.value) <= editor.value || editor.value == "" ?  e.target.value.toString().length < editor.value.toString().length ? e.target.value.replace(",",".")/10 : e.target.value.replace(",",".")*10 : e.target.value.replace(",",".")*10 : e.target.value.replace(",",".")/100).toFixed(2),
                                                            check: true
                                                        })
                                                    }
                                                    else{
                                                        setEditor({
                                                            idParticipant:body.id,
                                                            key: key,
                                                            indexExpense: expensesArray.indexOf(bodys[detailsTableSelect].expense),
                                                            value: parseFloat(e.target.value.toString().includes('.')  ? parseFloat(e.target.value) <= editor.value || editor.value == "" ?  e.target.value.toString().length < editor.value.toString().length ? e.target.value.replace(",",".")/10 : e.target.value.replace(",",".")*10 : e.target.value.replace(",",".")*10 : e.target.value.replace(",",".")/100).toFixed(2),
                                                            check: true
                                                        })
                                                    }
                                                }}
                                                onKeyUpCapture={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleDoubleClick(null)
                                                        buttonSelect == "Expenses" ? editParticipant(body.id,key,resumeSelect,e.target.value) : editParticipant(body.id,key,expensesArray.indexOf(bodys[detailsTableSelect].expense),e.target.value)  
                                                    }
                                                }}   
                                            />
                                        </div>
                                        ):(body[key])
                                    }
                                </td>
                            ))}
                        </tr>
                        
                    ))}
                    <tr  style={{marginBottom:"0.2em"}}>
                        <td colSpan='2' style={{display:'flex', alignContent:'start', verticalAlign:'center',}}>
                            <Button_More add_expenses={Add}/>
                            <button className="buttonDel"
                                onClick={(e)=>{Delete(true)}}
                            >Excluir</button>
                        </td>
                    </tr>
                    
                
                </tbody>
                
            </table>
            {buttonSelect != "Group" &&
                <div className="footerTable">
                    <div style={{width:'100%', maxHeight: "24px",display:'flex',flexDirection:'row'}}>
                        <div className="checkboxTable">
                            <label>
                                <input
                                    type="checkbox"
                                    //checked={item.isChecked}
                                    onChange={() => handleParticipation()}
                                />
                            </label>
                            <p style={{textAlign:"left", width:"55%", margin:"0px"}}>Exibir % de Participação</p>
                        </div>
                        <Instruction instruction={"Duplo clique para editar %Part e Gastos"}/>
                    </div>
                

                </div>
            }
           
            


        </div>
       
       
        
      
    )
    
}

export default Tables; 
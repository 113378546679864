import "../../css/Title.css"
const Instruction = ({instruction, style}) =>{
  

    return(
        <div className="instruction" style={{ marginBottom: style && style.marginBottom}}>
            <h1 style={{padding:style && style.paddingBottom}}>{instruction}</h1>
        </div>
       
    )
}

export default Instruction; 
import { useEffect, useState } from "react"
import InputMask from 'react-input-mask';
import {ButtonExpand} from "../Button"
import {Button_Delete} from "../Button"
import "../../css/Input_events.css"
import "../../css/Inputs.css"
import { SubTitle } from "../Title"




const Inputs = ({list,idevent,input,sender,AddSend,callback}) =>{
    //console.log("INPUT",input)
    let textButton = ""
    let textSubTitle = ""
    const [name,setName] = useState()
    const [expand,setExpand] = useState([])
    const [listObj,setListObj] = useState([{
        descrition:"",
        idevent:idevent,
        idParticipant: "",
        idExpense:"",
        id:"",
        name:"",
        title: "",
        isChecked: false,
        participation: "",
        contribuition: "",
    }])
    const [cleanAll,setCleanAll] = useState(false)
    const [clean,setClean] = useState()
    const [isChecked, setIsChecked] = useState(false)
    
   
    //console.log("LIST",list)

    if (sender == "expense" || sender == "expenseInput"){
        textButton = "Atribuir Participante"
        textSubTitle = "Quais participantes vão dividir esta despesa?"
    }
    else{
        textButton = "Atribuir Despesa"
        textSubTitle = "Quais despesas serão divididas com o participante?"
    }


    useEffect(()=>{
        if (list === null || list.length === 0 || list.response === "OK"){
            console.log("Entrou if")
            const listObj_ = [{
                descrition:"",
                idevent:idevent,
                idParticipant: "",
                idExpense:"",
                id:"",
                name:"",
                title: "",
                isChecked: false,
                participation: "",
                contribuition: "",
            }]
            setListObj(listObj_)
        }
        else{
            console.log("Entrou else")
            console.log("LENGHT",list.lenght)
            const listObj_ = list && list.map((item,i)=>{
                const obj = {
                    descrition:"",
                    idevent:idevent,
                    idParticipant: item.idParticipant,
                    idExpense:item.idExpense,
                    id: (sender == "expense" || sender == "expenseInput") ? item.idParticipant : item.idExpense,
                    name:item.name,
                    title: item.title,
                    isChecked: false,
                    participation: 0,
                    contribuition: "",
                }
                return obj
            })
            setListObj(listObj_)
        }
        setName("")
        if (input){
            const expand_ =[false]
            setExpand(expand_)
        }
        else{
            const expand_ =[true]
            setExpand(expand_)
        }

    },[list,callback])

    useEffect(()=>{
        if(listObj.lenght>0){
            const listObj_ = listObj.map((item,i)=>{
                const obj = {
                    descrition:item.descrition,
                    idevent:item.idevent,
                    id:item.id,
                    idParticipant: item.idParticipant,
                    isChecked: false,
                    participation: 0,
                    contribuition: "",
                }
                return obj
            })
            setListObj(listObj_)

        }
    },[cleanAll])
    useEffect(()=>{
        AddSend(listObj)
    },[listObj])
   
    

    //console.log("LIST_OBJ",listObj)

    const handleAllCheckboxChange = () =>{
        const updatedItems = listObj.map((item) => {
            if(isChecked){
                return { ...item, 
                    isChecked: false,
                    participation: 0,
                    contribuition: ""
                }
            }
            else{
                return { ...item, 
                    isChecked: true,
                    participation: 1,
                    contribuition: ""
                }

            }    
        })
        setListObj(updatedItems)
        setIsChecked(!isChecked)

    }

    const handleCheckboxChange = (itemId,itemParticipant) => {
        const updatedItems = listObj.map((item) => {
          if ((item.id === itemId && item.idParticipant == itemParticipant) && !item.isChecked) {
            return { ...item, 
                isChecked: !item.isChecked,
                participation: 1,
                contribuition: ""
            }
          }
          if((item.id === itemId && item.idParticipant == itemParticipant) && item.isChecked){
            return { ...item, 
                isChecked: !item.isChecked,
                participation: "",
                contribuition: ""
            }
          }
          return item
        })
        setListObj(updatedItems)
        setClean(itemId)
    }

    const handleChangeDescrition = (e) =>{
        const updatedItems = listObj.map((item) => {
            return { ...item, 
                descrition: e,
            }
          })
          setListObj(updatedItems)  
          setName(e)   
    }

    const handleChangeParticipation = (e,itemId) =>{
        const updatedItems = listObj.map((item) => {
            if (item.id === itemId) {
              return { ...item, 
                  participation: e.replace(",","."),
              }
            }
            return item
          })
          setListObj(updatedItems)
    }
    const handleChangeContribuition = (e,itemId,itemContribuition) =>{
        const updatedItems = listObj.map((item) => {
            if (item.id === itemId) {
                const eString = e.toString()
                console.log("E",eString)
                console.log("CONTRIBUITION",itemContribuition)
              return { ...item, 
                  contribuition: parseFloat(e.toString().includes('.')  ? parseFloat(e) <= itemContribuition || itemContribuition == "" ?  e.toString().length < itemContribuition.toString().length ? e.replace(",",".")/10 : e.replace(",",".")*10 : e.replace(",",".")*10 : e.replace(",",".")/100).toFixed(2),
                }
            }
            return item
          })
          setListObj(updatedItems)
    }
    const handleChangeExpand = () =>{
        const expand_ = expand.map((item)=> !item)
        setExpand(expand_)
        setCleanAll(!cleanAll)
    }

    console.log("LIST_OBJ",listObj)
   
    return(
        <div className="div_main">
            {input ? (
                <div className="div_input" style={{display:"flex"}}>
                    
                        <input
                        type="text"
                        name="name"
                        placeholder="Descrição"
                        value={name}
                        onChange={(e)=>{handleChangeDescrition(e.target.value)}}
                        />
                        <ButtonExpand Expand={handleChangeExpand} index ={0} expand ={expand} text={textButton} />
                        
                </div>
                

            ):(
                <div className="div_input">
                    <p>{(listObj != "" && listObj) && listObj[0].title}</p>
                </div>
            )}
            {expand[0] && listObj[0].name != "" &&
                <div style={{width: "100%", height:"93%"}}>
                    <SubTitle subTitle={textSubTitle} hightText={"75%"} height={"3%"} />
                    <div className="div_input_checked">
                        <div className="div_input_checked_block">
                            <label>
                                <input
                                    type="checkbox"
                                    //checked={item.isChecked}
                                    onChange={() => handleAllCheckboxChange()}
                                />
                            </label>
                            <p style={{textAlign:"left", width:"55%", margin:"0px"}}>Todos</p>
                        </div>
                    
                        {listObj  && listObj.map((item, index) => (
                            <div className="div_input_checked_block" key={item.id}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={item.isChecked}
                                        onChange={() => handleCheckboxChange(item.id,item.idParticipant)}
                                    />
                                    
                                </label> 
                                <p style={{textAlign:"left", width:"55%", margin:"0px"}}>{item.name}</p>
                                {item.isChecked && 
                                    <div className="divInputExpenses">
                                        <div className="textInputExpenes">
                                            <p>Participação</p>
                                            <p>Gastos</p>
                                        </div>
                                    <div >
                                        <input
                                            type="number"
                                            name="NPeople"
                                            value={item.participation}
                                            
                                            onChange={(e)=>{
                                                
                                                handleChangeParticipation(e.target.value,item.id)}}
                                        />
                                        {/*<InputMask
                                            mask="999999,99"
                                            maskChar="" 
                                            value={item.contribuition}
                                            onChange={(e)=>{handleChangeContribuition(e.target.value,item.id)}}
                                            
                                />*/}
                                    <input
                                            type="number"
                                            name="Contribution"
                                            value={item.contribuition}
                                            onChange={(e)=>{handleChangeContribuition(e.target.value,item.id,item.contribuition)}}
                                        />

                                        
                                        
                                    </div>
                                </div>
                                
                                }
                                
                            </div> 
                            ))
                        }
                    </div>
                </div>
            }


        
            
            
            
        </div>
        
    )
}

export default Inputs 
import { useState, useEffect, useRef } from "react"



export const useFetch = (url) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [url_action, setUrlAction] = useState(url);

    const [config, setConfig] = useState(null);
    const [method, setMethod] = useState(null);
    const [callFetch, setCallFetch] = useState(false);

    const error_ = useRef();
    const urlGetId = url + '/getidevent' 

    let url_ac


    const httpConfig = (data, method, action) => {

        
        url_ac = url + action; 
                      
        if(action != undefined){
            setUrlAction(url_ac)
        }
        const body_ = JSON.stringify(data)
        if(method === "POST"){
            
            setConfig({
                method,
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify(data),
            })
           
            setMethod(method);
            console.log("Entrou POST")
            
                               
        }
        else{
            const fetchdata = async () => {
           
                const res = await fetch(url_action);
    
                const json = await res.json();      
    
                setData(json);

                
                setMethod(method);
            }
            
            fetchdata();

        }

    }

   /* useEffect(() => {
        const fetchdata = async () => {
            
           
                const res = await fetch(url );

                const json = await res.json();      

                setData(json);
                console.log("GET normal")
                console.log(json)
                console.log(url)

            
                    
        }

        fetchdata();
        //console.log("Usefetch",data)
        //console.log("atualizou")

    },[callFetch])*/

    useEffect(() => {

        const httpRequest = async () => {


            if (method === "POST"){
                //console.log("Enviou POST")
                let fetchOppitions = [url_action, config]

                const res = await fetch(...fetchOppitions);
                
                const error_1 = await res.json();

                //console.log("ERROR_1",error_1)

                setError(error_1)

                //console.log("ERROR",error)
                        
               /* if(error_1 != "NOTCALLFETCH"){
                    if(callFetch === false){
                        setCallFetch(true);
                    }
                    else{
                        setCallFetch(false);
                    }
                }*/

            }
                   
        };
        
        httpRequest();  
        
       
    },[url_action, config, method,])

    return { data, error, httpConfig }
}
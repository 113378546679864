import "../../css/Footer.css"
import {Button_More} from "../Button"
import {Button_Delete} from "../Button"


const Footer_Button = ({buttons,functionButton}) =>{
    //console.log("Buttons",buttons)
    return(
        <div className="footer">
            {buttons.length > 0 && buttons.map((button,index)=>
                <button  
                
                    onClick={functionButton[index]}>{button == "Add" ? button : button}</button>
            )}
           
        </div>
    )
}
export default Footer_Button; 

import "../../css/TextHome.css"
import imagem from "../../imagens/divide.svg"
const TextHome = () =>{
    return(
        <div className="textHome">
            <p>Depois de Festejar é hora de dividir a conta!</p>
            <img src={imagem} />
        </div>
       
    )
}

export default TextHome; 
import { useState } from "react"
import {Button_More} from "../Button"
import {Button_Delete} from "../Button"
import "../../css/Input_events.css"




const Input_Events = ({receiveInput}) =>{
    const [expense, setExpense] = useState();
    const [expenses, setExpenses] = useState([]);
    const [event, setEvent] = useState({
        id_event:"",
        name:"",
        expenses: []
    })

    

    const handleChange_name = (e) =>{
        setEvent({
            ...event,
            name:e.target.value
        })   
    }
    const handleChange_expenses = (e) =>{
        setExpense(e.target.value) 
        console.log(expense)  
    }

    const add_expenses = () =>{
        expenses.push(expense)
        console.log()
        
        setEvent({
            ...event,
            expenses:expenses
        })
        setExpense()
    }
    
    const del = (index) =>{
        expenses.splice(index, 1)
        setEvent({
            ...event,
            expenses:expenses
        })
    }
    receiveInput(event)
    
    return(
        <div className="div_main_event">
            <div className="div_input">
                <input style={{width:"95%"}}
                type="text"
                name="name"
                placeholder="Nome do Evento"
                onChange={(e)=>{handleChange_name(e)}}
                />
            </div>
           {/* <div className="div_input">
                <p>O que será dividido?</p>
                <ul>
                    {event.expenses.length > 0 && event.expenses.map((expense, index) => (
                        <div>
                            <li key={index}>{expense}</li>
                            <Button_Delete del={del} index={index}/>
                        </div>
                        
                    ))}
                </ul>
                <div>
                    <input
                        type="text"
                        name="expense"
                        placeholder="Despesa"
                        value={expense}
                        onChange={(e)=>setExpense(e.target.value)}
                    />
                    <Button_More add_expenses={add_expenses}/>
                </div>
                
                    </div>*/}
        </div>
    )
}

export default Input_Events; 

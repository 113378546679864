import { Title } from "../../components/Title"
import { Footer_Button } from "../../components/Footer_Button"
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useFetch } from "../../hooks//useFetch"
import { useEffect, useState } from "react"
import { Instruction } from "../../components/Title"
import { PayToWho } from "../../components/Results";

const Results = () =>{
    let ideventObj = {id:""}
    //const urlEvents = "http://localhost:3000/events"
    const urlEvents = "https://divconta.com.br:21120/events"
    const navigate = useNavigate();

    const { data: dataReceive, error: receiveBD, httpConfig } = useFetch (urlEvents);
    const {idevent} = useParams()
    ideventObj.id = idevent

    let buttons = ["Resumo", "Redefinir"]

    const functionButton = [
        () =>{
            navigate('/Resume/' + idevent + "/Participants");
        },
        
        () =>{
           
        }
    ]

    useEffect(()=>{
        httpConfig(ideventObj,'POST','/getResults')
    },[])

    return(
        <div className="Container">
        <Title title ={receiveBD && receiveBD.eventName}/>
        <h1 className="subtituleResult">Quem paga Quem?</h1>
        <PayToWho body={receiveBD && receiveBD.result}/>
        <Footer_Button buttons={buttons} functionButton={functionButton} />
    </div>
    )        
}

export default Results
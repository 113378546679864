import {BrowserRouter, Route, Routes} from 'react-router-dom'
import { Participants, Results, Resume, Events, Home, Insert} from "../../pages"


const Router = () =>{
  
    return(
        <BrowserRouter>
            <Routes> 
                <Route path='/' element={<Home/>}/>
                <Route path = "/Events" element={<Events/>}/> 
                <Route path = "/Participants/:idevent" element={<Participants/>}/> 
                <Route path = "/Results/:idevent" element={<Results/>}/> 
                <Route path = "/Resume/:idevent/:where" element={<Resume/>}/> 
                <Route path = "/Insert/:idevent/:sender/:index/:eventName" element={<Insert/>}/>
                
            </Routes>
        </BrowserRouter>
    )
}

export default Router; 

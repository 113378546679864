import { useState } from "react"
import Button_more_svg from "../../imagens/add.svg"
import "../../css/Button_Input.css"



const Button_More = ({add_expenses}) =>{
    
    return(
        <button 
            className="buttonMore"
            onClick={(e)=>{add_expenses(true)}}>
            <img src={Button_more_svg}/>
        </button>
         
    )
}

export default Button_More; 

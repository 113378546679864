import { useState } from "react"
import ButtonExpandDown from "../../imagens/down.svg"
import ButtonExpandRight from "../../imagens/right.svg"
import "../../css/Button_Input.css"


const ButtonExpand = ({Expand,index, expand, text}) =>{

    //expand && (expand[index] ? <img  src={ButtonExpandDown} width={'50%'} height={'50%'}/> : <img  src={ButtonExpandRight} width={'50%'} height={'50%'} />)}
    if(text == undefined){
        text = "Ver Detalhe"
    }
    return(
        <button 
            className="button_input"
            onClick={(e)=>{Expand(index)}}>
            {expand && (expand[index] ? "Ocultar" : text)} 
        </button>
         
    )
}

export default ButtonExpand; 

import { Input_Events } from "../../components/Input"
import { Title } from "../../components/Title"
import { Footer_Button } from "../../components/Footer_Button"
import { useNavigate, useParams } from 'react-router-dom';


import { useFetch } from "../../hooks//useFetch"


import "../../css/Container.css"
import { useState, useEffect } from "react";


const Events = () =>{

    //const urlEvent = "http://localhost:3000/events"
    const urlEvent = "https://divconta.com.br:21120/events"
    const navigate = useNavigate();
    const [event, setEvent] = useState()
    const [buttons,setButtons] = useState(["Voltar", "Criar"])
    const [callBack,SetCallBack] = useState(false)

    const { data: event_, error: idevent, httpConfig: httpConfigEvent } = useFetch (urlEvent);

   
    


    const functionButton = [
        () =>{//Function Button Voltar
            navigate('/');
        },
        () =>{//Function Button Participantes
            
            httpConfigEvent(event,"POST","/insert")
            //buttons.push("Resumo")
            /*console.log("IDEVENT",idevent)
            navigate('/Resume/' + idevent);*/
            SetCallBack(!callBack)
          
        },
        () =>{//Function Button Voltar
            navigate('/Resume/' + idevent + "/Participants");
           
        },

    ]

    const receiveInput = (events)=>{
        setEvent(events)
    }

    useEffect(()=>{
        if(callBack && idevent){
            navigate('/Resume/' + idevent + "/Participants")
            
        }
        
    },[callBack,idevent])
    return(
        <div className="Container">
            <Title title ={"Evento"}/>
            <Input_Events receiveInput={receiveInput}  />
            <Footer_Button buttons={buttons} functionButton={functionButton} />
        </div>
        
    )        
}

export default Events
import { useEffect, useState } from "react"
import {ButtonExpand} from "../Button"
import {Button_Delete} from "../Button"
import "../../css/Input_events.css"




const InputParticipants = ({event,add,clean,idevent}) =>{
  
    const [expand,setExpand] = useState([])
    const [expenses, setExpenses] = useState([]);
    const [participant, setParticipant] = useState()
    console.log("EXPAND",expand)
    
    useEffect(()=>{
        console.log("Entrou useEfect InputParticipants")
        const expand_ = event.map((item, i) => {
            return false;
        });
        setExpand(expand_)
        const expenses_ = event.map((item)=>{
            let expense_ = {
                name:"",
                qntPeople:"",
                contribuition:"",
                amountOwed:"",
                amountToPaid:""
            }
            expense_.name = item
            return expense_
        })
        setExpenses(expenses_)
        setParticipant("")
        
        
    },[event,clean])
    

    const handleChange_name = (e) =>{
        setParticipant(e.target.value)  
        add(expenses,participant,idevent) 
    }

    const handleChangeExpenseQnt = (e,index) =>{
        const expenses_ = expenses.map((item, i) => {
            if (i === index) {
                item.qntPeople = e.target.value
                return item         
            } else {
                return item
            }
          });
        setExpenses(expenses_)
        add(expenses,participant,idevent)
    }
    const handleChangeExpenseCont = (e,index) =>{
        const expenses_ = expenses.map((item, i) => {
            if (i === index) {
                item.contribuition = e.target.value
                return item         
            } else {
                return item
            }
          });
        setExpenses(expenses_)
        add(expenses,participant,idevent)
    }
        

    //console.log("EXPENSES",expenses)
    
    const Expand = (index) =>{
        const expand_ = expand.map((item, i) => {
            if (i === index) {       
              return !item;
            } else {
              return item;
            }
          });
        setExpand(expand_)
    }
    
    return(
        <div className="div_main">
            <div className="div_input">
                <input
                    type="text"
                    name="name"
                    placeholder="Nome do Participante"
                    value={participant}
                    onChange={(e)=>{handleChange_name(e)}}
                />
            </div>
            
            <div className="divBodyExpenses">
                { event.map((exp,index)=>(
                    <div className="div_input">
                        <div className="textInputParticipants">
                            <p>{exp}</p>
                            <ButtonExpand Expand={Expand} index={index} expand={expand} />
                        </div>
                        {expand[index] && 
                            <div className="divInputParticipants">
                                <div className="textInputParticipantsExpenes">
                                    <p>Quant Pessoas</p>
                                    <p>Contribuição</p>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        name="NPeople"
                                        placeholder="Quant Pessoas"
                                        value={expenses[index].qntPeople}
                                        onChange={(e)=>{handleChangeExpenseQnt(e,index)}}
                                    />
                                    <input
                                        type="text"
                                        name="Contribution"
                                        placeholder="Contribuição"
                                        value={expenses[index].contribuition}
                                        onChange={(e)=>{handleChangeExpenseCont(e,index)}}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                ))}

            </div>
            
            
            
        </div>
    )
}

export default InputParticipants; 



import './App.css';
import {Router} from './components';


function App() {
  return (
    <div>
      <Router/>
      
    </div>
  );
}

export default App;

import {Tables} from "../Tables"
import { useEffect, useState } from "react"
import {ButtonExpand} from "../Button"
import "../../css/Input_events.css"
import { ButtonResume } from "../../components/ButtonResume"

const ResumeTableBox = ({expensesEvents,participantsOrder,expensesOrder, ResumeSelect_, resumeSelect, ExpandSelect_, DetailsSelect_, buttonSelect, participantsEvents, editParticipant, ButtonSelect, confirmExpand, Add, Delete}) =>{

    
    const [expand,setExpand] = useState([])
    const [body,setBody] = useState([])
    const [editId, setEditId] = useState(null)
    
 
    let resume = []
    let details = []

    
    if (buttonSelect == "Expenses"){
        resume = expensesEvents
        details =participantsOrder
    }
    if(buttonSelect == "Participants"){
        resume = participantsEvents
        details = expensesOrder
    }
    
   
    useEffect(()=>{
        if(buttonSelect == "Expenses"){
            if(expensesEvents){
               // if(expand.length != expensesEvents.length){
                    const expand_ = expensesEvents.map((item, i) => {
                        console.log("CONFIRM",confirmExpand)
                        if(i == resumeSelect && confirmExpand){
                            return true
                        }
                        else{
                            return false
                        }
                    })
                    setExpand(expand_)
               // }
            }
        }
        if(buttonSelect == "Participants"){
            if(participantsEvents){
                //if(expand.length != participantsEvents.length){
                    const expand_ = participantsEvents.map((item, i) => {
                         if(i == resumeSelect && confirmExpand){
                              return true
                          }
                          else{
                              return false
                          }
                    })
                    setExpand(expand_)
                //}
            }
        }
    },[buttonSelect,participantsEvents, expensesEvents])

  /*  useEffect(()=>{
        
        if(details != null){
            const details_ = details.map((item, i) => {
                return false;
            });
            
            setBody(details_) 
        }
        
    },[expensesEvents])*/

    const Expand = (index) =>{
        console.log("EXPAND_INDEX",index)
        const expand_ = expand.map((item, i) => {
            if (i === index) {       
              return !item;
            } else {
              return item;
            }
        });
        setExpand(expand_)
        handleDoubleClick(null)
        //setResumeSelect(index)
        ResumeSelect_(index)
        DetailsSelect_()
    }
    useEffect(()=>{
        ResumeSelect_(resumeSelect)
       // DetailsSelect_()
        
    },[resumeSelect])

    useEffect(()=>{
        ExpandSelect_(expand)
    },[expand, ])

    const handleDoubleClick = (index)=>{
        setEditId(index);
        
    }

   /* const handleMouseDown = () => {
        setStartTime(new Date());
    }
    
    const handleMouseUp = (index) => {
        if (startTime !== null) {
          const currentTime = new Date();
          const diff = currentTime - startTime;
    
            if (diff >= 1000) {
                
                handleDoubleClick(index)
                
            } 
            else{
                handleDoubleClick(null)
            }
    
          setStartTime(null);
        }
    }*/
    console.log("RESUME",resume)
    return(
        <div className="divBodyExpenses">
                {resume && (resume.length > 0 ? resume.map((exp,index)=>(
                    <div 
                        className="div_input"
                        onClick={(e)=>{
                            //setResumeSelect(index)
                            ResumeSelect_(index)
                            handleDoubleClick(null)
                        }}
                        //onDoubleClick={()=>{Expand(index)}}
                        style={{backgroundColor: index == resumeSelect && "#bec8d4" }}
                    >
                        <div className="textInputParticipants" 
                            onDoubleClick={()=>handleDoubleClick(index)}
                            // onTouchStart={()=>handleMouseDown(index)}
                        //  onTouchEnd={()=>handleMouseUp(index)}
                        >
                            {editId == index && resumeSelect == index ? (
                                <input
                                    type="text"
                                    defaultValue={exp.name}
                                    autoFocus="true"
                                    onKeyUpCapture={(e) => {
                                        if (e.key === 'Enter') {
                                            handleDoubleClick(null)
                                            
                                            buttonSelect == "Expenses" ? editParticipant(0,'expense',resumeSelect,e.target.value,) : editParticipant(participantsEvents[index].id,'name',0,e.target.value)  
                                        }
                                    }}
                                />
                                ):(<p>{exp.name}</p>)
                            }
                            <ButtonExpand Expand={Expand} index={index} expand ={expand} />
                        </div>
                        {buttonSelect == "Participants" ?
                            <div style={{borderBottom: expand[index] && '1px solid #000',display: "felex", flexDirection: exp.contribuitionAll >= 10000 ? "column" : exp.amountOwedAll >= 10000 ? "column" : exp.amountToPay >=10000 ? "column":"row"}}>
                                <div className="textResumeParticipant">
                                    <p>Gastos:</p>
                                    <p style={{color:'#993030'}}>{exp.contribuitionAll.toLocaleString('pt-BR')}</p>
                                </div>
                                <div className="textResumeParticipant">
                                    <p>Devido:</p>
                                    <p style={{color:'#237A27'}}>{exp.amountOwedAll}</p>
                                </div>
                                <div className="textResumeParticipant">
                                    {exp.amountToPay > 0 ?
                                        <div>
                                            <p>Pagar:</p>
                                            <p style={{color:'#993030'}}>{exp.amountToPay}</p>
                                        </div>
                                        :
                                        <div>
                                            <p>Receber:</p>
                                            <p style={{color:'#223497'}}>{exp.amountToReceive}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            <div className="textResumeParticipant" style={{borderBottom: expand[index] && '1px solid #000'}}>
                                    <p>Gastos:</p>
                                    <p style={{color:'#993030'}}>{exp.amountExpenses}</p>
                            </div>
                        }
                        {expand[index] && 
                            <div className="divInputParticipants">
                                <Tables bodys = {details[index]} heads ={[buttonSelect == "Participants" ? "Despesas para dividir" :"Com quem dividir" , "%Part", "Gastos", "Devido"]} DetailsSelect_ = {DetailsSelect_} expensesEvents = {expensesEvents} participantsEvents={participantsEvents} buttonSelect = {buttonSelect} resumeSelect = {resumeSelect} editParticipant = {editParticipant} ButtonSelect = {ButtonSelect} Expand = {Expand} Add={Add} Delete={Delete}/>
                            </div>
                        }
                    </div>
                )):<p className="startMsg">{buttonSelect == "Participants"? "Clique em Add para adiconar Participantes" : buttonSelect == 'Expenses' ? "Clique quem Add para adicionar Despesas":""}</p>)}

            </div>


    )
    
}

export default ResumeTableBox; 
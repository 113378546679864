import {Tables} from "../Tables"
import { useEffect, useState } from "react"
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from "react-dnd-touch-backend";
import {ButtonExpand} from "../Button"
import "../../css/Input_events.css"
import {GroupsDrag} from "../Groups"
import { Instruction } from "../Title";

const Groups = ({groupEvents, DetailsSelect_, expensesEvents, buttonSelect, participantsEvents, agroupParticipnt, resumeSelect, ResumeSelect_ }) =>{

    
    const [expand,setExpand] = useState([])
    //const [resumeSelect, setResumeSelect] = useState()
    const [resume, setResume] = useState()
    const [details, setDetails] = useState()
    
   
    useEffect(()=>{
        setDetails(groupEvents.groupOrder)
        setResume(groupEvents.groupEvent)
    },[groupEvents])

 
    
   

    //console.log("RESUME",resume)
    //console.log("DETAILS",details)
   
    useEffect(()=>{
        if(resume){
            if(expand.length != resume.length){
                const expand_ = resume.map((item, i) => {return false})
                setExpand(expand_)
            }
        }
    },[resume])

    const Expand = (index) =>{
        const expand_ = expand.map((item, i) => {
            if (i === index) {       
              return !item;
            } else {
              return item;
            }
        });
        setExpand(expand_)
        ResumeSelect_(index)
    }

    /*const ResumeSelect = (index) => {
        setResumeSelect(index)
    }*/

    const handleDrop = (draggedGroup, droppedGroup) => {
        // Encontra os nomes correspondentes aos IDs
        const draggedName = resume.find((item) => item.group === draggedGroup).leader;
        const droppedName = resume.find((item) => item.group === droppedGroup).leader;
    
        
    
        // Remove o nome arrastado da lista
        const filteredResume = resume.filter((item) => item.group !== draggedGroup);
    
        //setResume(filteredResume);
      };




     
    return(
            <div className="divBodyExpenses" style={{height:"98%"}}>
                {resume && resume.map((exp,index)=>(
                    <DndProvider backend={TouchBackend}
                    options={{ enableMouseEvents: true }}
                    >
                        {/*<div 
                            className="div_input"
                            onClick={(e)=>{
                                setResumeSelect(index)
                            }}
                            style={{backgroundColor: index == resumeSelect && "#bec8d4" }}
                        >
                            <div className="textInputParticipants">
                                
                                <p>{exp.leader}</p>
                                
                                <ButtonExpand Expand={Expand} index={index} expand ={expand} />
                            </div>
                            
                            <div>
                                <div className="textResumeParticipant">
                                    <p>Gastos:</p>
                                    <p style={{color:'#993030'}}>{exp.contribuitionAll}</p>
                                </div>
                                <div className="textResumeParticipant">
                                    <p>Devido:</p>
                                    <p style={{color:'#237A27'}}>{exp.amountOwedAll}</p>
                                </div>
                                <div className="textResumeParticipant">
                                    {exp.amountToPay > 0 ?
                                        <div>
                                            <p>Pagar:</p>
                                            <p style={{color:'#993030'}}>{exp.amountToPay}</p>
                                        </div>
                                        :
                                        <div>
                                            <p>Receber:</p>
                                            <p style={{color:'#223497'}}>{exp.amountToReceive}</p>
                                        </div>
                                    }
                                </div>
                            </div>   
                            
                            {expand[index] && 
                                <div className="divInputParticipants">
                                    <Tables bodys = {details[index]} heads ={["Nome", "Gasto", "Devido", "Quitar"]} expensesEvents = {expensesEvents} participantsEvents={participantsEvents} buttonSelect = {buttonSelect} resumeSelect = {resumeSelect} />
                                </div>
                            }
                        </div>*/}
                        <GroupsDrag group = {exp} DetailsSelect_ = {DetailsSelect_} body ={details[index]} expensesEvents = {expensesEvents} participantsEvents = {participantsEvents} buttonSelect = {buttonSelect} expand = {expand} Expand = {Expand} index ={index} agroupParticipnt={agroupParticipnt} resumeSelect={resumeSelect} ResumeSelect_ = {ResumeSelect_} />
                    </DndProvider>
                ))}

            </div>
    )
    
}

export default Groups; 
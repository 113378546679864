import { useEffect, useState } from "react";
import "../../css/Footer.css"


const ButtonResume = ({ButtonSelect, ResumeSelect_,buttonSelect}) =>{
    

    const handleOnClick = (buttonSelect) =>{
        ResumeSelect_(undefined)
        ButtonSelect(buttonSelect) 
        
    }


    return(
            <div className="buttonResume">
                <button style={{backgroundColor: buttonSelect == "Participants" ? "#9596af" : '#e0e1ee'}} 
                    onClick={(e) =>{
                    handleOnClick("Participants")
                }}
                    >Participantes</button>
                <button style={{backgroundColor: buttonSelect == "Expenses" ? "#9596af" : '#e0e1ee'}}
                    onClick={(e) =>{
                    handleOnClick("Expenses")
                }}
                    >Despesas</button>
                <button style={{backgroundColor: buttonSelect == "Group" ? "#9596af" : '#e0e1ee'}}
                    onClick={(e) =>{
                    handleOnClick("Group")
                }}>
                    Grupos</button>  
            </div>     
            
        
 
        
        
    )
}
export default ButtonResume; 

import "../../css/PayToWho.css"

const PayToWho = (body) => {

    console.log("BODY",body)

    return (
        <div className="PayToWho">
            <table>
                <thead>
                    <tr>
                        <th>Participante</th>
                        <th>Pagar Para</th>
                        <th>Participante</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    {body.body && body.body.map((row,i)=>(
                        <tr>
                            <td>{row.participantPay}</td>
                            <td>Pagar Para</td>
                            <td>{row.participantReceive}</td>
                            <td>{row.amount}</td>
                            
                        </tr>

                    ))}
                    
                </tbody>
            </table>
            <p className="startMsg" style={{marginTop: "2%"}}>Clique em Resumo para ver os Detalhes</p>

        </div>
    )
}

export default PayToWho
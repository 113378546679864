import "../../css/Title.css"
const SubTitle = ({subTitle, hightText, height}) =>{

    const styleSubTitle = {
        width: '100%',
        background:  '#AFB2D2',
        margin:'1%',
        height: height,
       /* vertical-align: 'middle',
        align-items: 'center',
        align-content: 'center',*/
    }

    const styleH1 = {
        color:'rgb(42, 42, 46)',
        font: "Verdana, Geneva, Tahoma, sans-serif",
        fontSize:hightText,
        margin: "0",
        border: "0",
        align: "center",
        padding: "0"


    }
    

    return(
        <div className="subtitle">
            <h1>{subTitle}</h1>
        </div>
       
    )
}

export default SubTitle; 